<template>
  <div
    v-if="blok.key?.length || blok.text?.length"
    :class="['key-number size-s', { 'full-width': blok.is_full_width }]"
  >
    <div v-if="isMobile">
      <StoryblokComponent v-if="blok.image?.length" :blok="blok.image[0]" />
    </div>
    <p class="key-number-content">
      <template v-if="blok.textPosition === 'top'">
        <span v-if="blok.text" class="key-number-text">{{ blok.text }}</span>
        <span class="key-number-key size-m bold">
          <strong class="size-2xl">{{ blok.key + " " }}</strong>
          <template v-if="blok.unit">{{ blok.unit }}</template>
        </span>
      </template>
      <template v-else>
        <span class="key-number-key size-m bold">
          <strong class="size-2xl">{{ blok.key + " " }}</strong>
          <template v-if="blok.unit">{{ blok.unit }}</template>
        </span>
        <span v-if="blok.text" class="key-number-text">{{ blok.text }}</span>
      </template>
    </p>
  </div>
</template>

<script setup>
defineProps({
  blok: {
    type: Object,
    required: true,
  },
});

const isMobile = inject("isMobile");
</script>

<style lang="scss" scoped>
.key-number {
  display: flex;
  grid-column: span 6;
  align-items: center;
  padding: 2.5rem;
  @include pair-3;
  border-radius: $radius-l;
  &-key {
    display: block;
    background: -webkit-linear-gradient(-90deg, var(--gradient-start) 0%,  var(--gradient-end) 100%) no-repeat 0 50%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &.full-width .key-number-content {
    display: block;
    width: 100%;
    @include for-tablet-portrait-up {
      width: auto;
      display: flex;
      align-items: flex-end;
      gap: 2rem;
    }
    .key-number-text {
      display: block;
      text-align: right;
    }
  }
}
.alternate .key-number {
  @include pair-7;
}
</style>
